import React from "react";
import "./index.css";

const Logo = () => {
  return (
    <div className="logo">
      <div>My</div>
      <div>Cook Book</div>
    </div>
  );
};

export default Logo;
